import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Dict, LazyLoadDict } from '../../../../core/interface';
import { DspService } from '../../../../core/services/dsp.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-geo-revshare',
  templateUrl: './geo-revshare.component.html',
  styleUrls: ['./geo-revshare.component.scss']
})
export class GeoRevshareComponent implements OnInit {
  @Input()
  revShares: FormArray;

  @Input()
  maxValue: number = 100;

  geos: LazyLoadDict = {
    data: []
  };

  filterValue: Record<number, string> = {};

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private dspService: DspService
  ) {}

  ngOnInit(): void {
    this.loadGeos();
  }

  /**
   * Load geos dict
   */
  loadGeos(): void {
    const dict = this.geos;

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request = {
      limit: 500,
      page: 1
    };

    this.dspService
      .getCountries(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ data, meta }) => {
          dict.isLoading = false;
          dict.data = dict.isLazy ? dict.data.concat(data) : data;
          dict.meta = meta;
          dict.isLazy = dict.meta ? dict.meta.total > dict.data.length : false;
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Add new geo by click
   * @param geoId
   */
  addGeo(geoId?: string): void {
    const newGeo = this.fb.group({
      geo: [geoId || '', Validators.required],
      revenue: [0, [Validators.min(0), Validators.max(this.maxValue)]]
    });

    this.revShares.push(newGeo);
  }

  /**
   * Remove geo by click
   * @param i
   */
  removeGeo(i: number) {
    this.revShares.removeAt(i);
    this.revShares.markAsDirty();
  }

  /**
   * Return geo name from dict
   * @param id
   */
  getGeoValue(id: string): string {
    const geoObject = this.geos.data.find(x => x.id === id);
    return geoObject ? geoObject.value : '';
  }

  /**
   * Filtered geo
   * @param index
   */
  filteredGeos(index: number): Dict[] {
    const geosArr = this.revShares.value.map(x => x.geo);
    const filterValue = this.filterValue[index] ?? '';

    return this.geos.data.filter(x => {
      return geosArr.indexOf(x.id) === -1 && x.value.toLowerCase().includes(filterValue.toLowerCase());
    });
  }
}
