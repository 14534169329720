import { Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DspService } from '../../../../core/services/dsp.service';
import { ExchangeFilterItemDict, ExchangeFilterType, LoadExchangeDictConfig } from '../../../../core/interface/exchange';
import { BulkSelectModalResponse, Config, Dict, LazyLoadDict, LoadSspDictConfig, ValidateBulkSelect } from '../../../../core/interface';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { TranslocoService } from '@jsverse/transloco';
import { BulkPopupComponent } from '../../../trade-desk/modules/campaign-form/components/bulk-popup/bulk-popup.component';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { ExchangeService } from '../../exchange.service';
import { environment } from '../../../../../environments/environment';
import { debounceTime, distinctUntilChanged, finalize, skip } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-exchange-filter',
  templateUrl: './exchange-filter.component.html',
  styleUrls: ['./exchange-filter.component.scss']
})
export class ExchangeFilterComponent implements OnInit {
  @Input({ required: true })
  group: FormGroup;

  @ViewChild('scrollWrapper')
  scrollWrapper: ElementRef;

  isLoading = false;
  isLoadingSubmit = false;
  availableSearchControl = new FormControl();
  selectedSearchControl = new FormControl();
  inputValue: string = '';
  filterValue: string = '';
  filters: any[] = [];

  modeType = [
    { id: true, value: 'Include selected' },
    { id: false, value: 'Exclude selected' }
  ];

  dictFilters = ['sspFilter', 'dspFilter', 'trafficCategoryFilter', 'osFilter', 'browsersFilter', 'geoFilter'];

  dicts = {
    available: {
      dict: { data: [] },
      isLazy: false,
      excludes: [],
      isLoading: false
    },
    selected: {
      dict: { data: [] },
      isLazy: false,
      includes: [],
      isLoading: false
    }
  };

  private config: Config = environment;
  private destroyRef = inject(DestroyRef);

  constructor(
    private dspService: DspService,
    private alerts: AdxadAlerts,
    private translate: TranslocoService,
    private modal: AdxadModal,
    private exchangeService: ExchangeService
  ) {}

  ngOnInit(): void {
    this.prepareFilter();
  }

  /**
   * Return selected filter
   */
  get selectedFilter() {
    return this.filters.find(x => x.isSelected);
  }

  /**
   * @return {FormControl} form control
   */
  get filterValues(): FormControl {
    return this.group.get(this.selectedFilter.id).get('value') as FormControl;
  }

  /**
   * @return {boolean} flat to disable select all btn
   */
  get isDisabledSelectBtn(): boolean {
    return !this.dicts.available.dict.data.length;
  }

  /**
   * @return {boolean} flat to disable clear all btn
   */
  get isDisabledClearBtn(): boolean {
    if (this.type === 'subId') {
      return !this.selectedFilter.value.length;
    } else {
      return !this.dicts.selected.dict.data.length;
    }
  }

  /**
   * @return {boolean} flag to show available loader
   */
  get isAvailableLoader(): boolean {
    const availableDict = this.dicts.available;
    return availableDict.isLoading || availableDict.isLazy;
  }

  /**
   * @return {boolean} flag to show selected loader
   */
  get isSelectedLoader(): boolean {
    const selectedDict = this.dicts.selected;
    return selectedDict.isLoading || selectedDict.isLazy;
  }

  /**
   * @return {ExchangeFilterType} type of selected target
   */
  get type(): ExchangeFilterType {
    return this.selectedFilter.type;
  }

  /**
   * @return {boolean} flag of disable add btn
   */
  get disabledAddBtn(): boolean {
    return !this.inputValue.trim().length;
  }

  /**
   * @return {Dict[]} filtered list of available items
   */
  get available(): Dict[] {
    if (!this.filterValues.value && !this.dicts.available.dict.data.length) {
      return [];
    }

    return this.dicts.available.dict.data;
  }

  /**
   * @return {Dict[]} filtered list of selected items
   */
  get selected(): Dict[] {
    if (!this.filterValues.value && !this.dicts.selected.dict.data.length) {
      return [];
    }

    return this.dicts.selected.dict.data;
  }

  prepareFilter(): void {
    if (this.isLoading || !this.group.value) {
      return;
    }

    const entries = Object.entries(this.group.value);

    this.filters = entries.map(([key, value], index) => ({
      id: key,
      label: key,
      mode: value['mode'],
      value: value['value'],
      isSelected: index === 0,
      type: this.dictFilters.includes(key) ? 'dict' : 'subId'
    }));

    if (entries.length > 0) {
      this.subscribeToValueChanges(this.availableSearchControl, 'available');
      this.subscribeToValueChanges(this.selectedSearchControl, 'selected');

      if (this.filters[0].type === 'dict') {
        this.loadDicts();
      }
    }
  }

  /**
   * Change selected filter
   * @param {string} id
   */
  selectFilter(id: string): void {
    if (this.selectedFilter.id === id) {
      return;
    }

    let found = false;

    for (const filter of this.filters) {
      if (filter.id === id) {
        filter.isSelected = true;
        found = true;
        this.inputValue = '';
      } else {
        filter.isSelected = false;
      }
    }

    if (found && this.selectedFilter.type === 'dict') {
      if (this.scrollWrapper && this.scrollWrapper.nativeElement) {
        this.scrollWrapper.nativeElement.scrollTop = 0;
      }
      this.reset();
    }
  }

  /**
   * Load available & selected dicts
   */
  loadDicts(): void {
    this.loadAvailableData();
    if (this.filterValues.value.length) {
      this.loadSelectedData();
    }
  }

  loadDictsData({ value, isNewSearch, type }: LoadExchangeDictConfig): void {
    const dict: ExchangeFilterItemDict = this.dicts[type];

    if (dict.isLoading) {
      return;
    }

    if (isNewSearch) {
      dict.dict.data = [];
    }

    const key = type === 'available' ? 'excludes' : 'includes';
    this.dicts.available[key] = [...this.filterValues.value];
    this.dicts.selected[key] = [...this.filterValues.value];

    const request = {
      limit: 200,
      page: !isNewSearch && dict.isLazy && dict.dict?.meta ? dict.dict.meta.current_page + 1 : 1,
      search: value ?? '',
      ...(type === 'available' ? { excludes: dict.excludes } : { includes: dict.includes })
    };

    dict.isLoading = true;

    this.getMethodForSelectedFilter(this.selectedFilter.id, request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => this.processResult(result, dict, isNewSearch),
        error: () => {
          dict.isLoading = dict.isLazy = false;
          this.isLoading = false;
        },
        complete: () => (this.isLoading = false)
      });
  }

  selectedItemCount(item: string): number {
    return this.group.get(item).get('value')?.value.length;
  }

  /**
   * Detect scroll for lazy load
   * @param {Event} e
   * @param {string} type of dict: selected or available
   */
  scrollTargets(e: Event, type: 'available' | 'selected'): void {
    const dict = this.dicts[type];
    const target = e.target as HTMLElement;

    const reachedBottom = Math.ceil(target.scrollTop + target.offsetHeight) >= target.scrollHeight;

    if (dict.isLazy && reachedBottom) {
      const searchControl = type === 'available' ? this.availableSearchControl : this.selectedSearchControl;
      this.loadDictsData({
        value: searchControl.value,
        isNewSearch: false,
        type: type
      });
    }
  }

  /**
   * Open bulk select modal
   * @param invalidData
   */
  openBulkSelectedDialog(invalidData?: string[]): void {
    this.modal
      .open(BulkPopupComponent, {
        width: '588px',
        data: {
          invalidData,
          id: this.selectedFilter.id,
          ...this.group.get(this.selectedFilter.id).value
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result: BulkSelectModalResponse) => {
        if (result?.submit) {
          const setData = result.data.length ? [...new Set(result.data)] : [];
          this.filterValues.setValue(setData);
          if (!result.data.length || this.type === 'subId') {
            this.reset();
          } else {
            this.checkMassAddIds(result.data, this.selectedFilter.id);
          }
        }
      });
  }

  /**
   * Select all
   * If dict is lazy, load next page
   */
  selectAll(): void {
    if (this.isDisabledSelectBtn) {
      return;
    }

    this.available.forEach(x => this.add(x));

    if (this.dicts.available.isLazy) {
      const data = {
        value: this.availableSearchControl.value,
        isNewSearch: false,
        type: 'available'
      } as LoadSspDictConfig;

      this.loadDictsData(data);
    }
  }

  /**
   * Check ids from dict
   *
   * @param {string[]} ids
   * @param {string} filterId
   */
  checkMassAddIds(ids: string[], filterId: string): void {
    this.isLoading = true;
    const request = { ids };
    const urlPaths = {
      geoFilter: 'countries',
      dspFilter: 'list/exchange_dsp',
      trafficCategoryFilter: 'trafficCategory',
      browsersFilter: 'browsers',
      osFilter: 'os',
      sspFilter: 'list/exchange_ssp'
    };

    const validateUrl = `${this.config.dict_endpoint}/${urlPaths[filterId]}/check`;

    this.exchangeService
      .validateBulkSelect(validateUrl, request)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: ({ data, status }: ValidateBulkSelect) => {
          if (status === 'OK') {
            if (data.validCount && !data.invalidCount) {
              const addMessage = `Added ${data.validCount} ${this.translate.translate(filterId)}`;
              this.alerts.success(addMessage, 3000);
            }
            this.filterValues.setValue(data.data);

            if (data.invalidCount) {
              this.openBulkSelectedDialog(data.invalidData);
            }

            this.reset();
          }
        }
      });
  }

  /**
   * Clear all
   * If dict is lazy, load next page
   */
  clearAll(): void {
    if (this.isDisabledClearBtn) {
      return;
    }

    if (this.type === 'subId') {
      this.clearFilterValues();
    } else {
      this.clearSelected();
    }
  }

  /**
   * Remove selected filter value
   * @param {Dict} item
   */
  remove(item: Dict | string): void {
    const index = this.filterValues.value.findIndex(x => x === item);
    this.filterValues.value.splice(index, 1);
    this.dicts.selected.dict.data = this.dicts.selected.dict.data.filter(x => x.id !== item['id']);
    this.dicts.available.dict.data.unshift(item);
  }

  /**
   * Add filter value
   */
  add(item?: Dict | string): void {
    if (this.type === 'subId') {
      let value = this.filterValues.value ? this.filterValues.value : [];
      const id = this.inputValue.trim();

      if (value.find(x => x === id)) {
        this.alerts.warning(this.translate.translate('alert_thisValueIsAlreadyExist'), 2000);
        return;
      }

      value.push(id);
      this.filterValues.setValue(value);
      this.inputValue = '';
    } else {
      this.filterValues.value.unshift(item['id']);
      this.dicts.selected.dict.data.unshift(item);
      this.dicts.available.dict.data = this.dicts.available.dict.data.filter((x: Dict) => x.id !== item['id']);
    }

    this.group.updateValueAndValidity();
  }

  /**
   * Keyboard listener on input
   */
  keyboardListener(e): void {
    if (e.code === 'Enter') {
      e.preventDefault();
      this.add();
    }
  }

  private subscribeToValueChanges(control: AbstractControl, type: string): void {
    control.valueChanges.pipe(skip(1), debounceTime(500), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        if (type === 'selected' && !this.filterValues.value.length) {
          return;
        }

        const data = {
          value: value,
          isNewSearch: true,
          type: type
        } as LoadExchangeDictConfig;

        this.loadDictsData(data);
      }
    });
  }

  private getMethodForSelectedFilter(id: string, request: any): Observable<LazyLoadDict> {
    switch (id) {
      case 'geoFilter':
        return this.dspService.getCountriesWithoutExtra(request);
      case 'dspFilter':
        return this.dspService.getExchangeDsp(request);
      case 'trafficCategoryFilter':
        return this.dspService.getTrafficCategory(request);
      case 'browsersFilter':
        return this.dspService.getBrowsers(request);
      case 'osFilter':
        return this.dspService.getOs(request);
      case 'sspFilter':
        return this.dspService.getExchangeSsp(request);
      default:
        throw new Error(`Unrecognized filter ID: ${id}`);
    }
  }

  /**
   * Reset component
   * Clear filter values
   * Scroll on top
   * reset includes
   * load dicts
   */
  reset(): void {
    this.availableSearchControl.setValue('');
    this.selectedSearchControl.setValue('');

    if (this.scrollWrapper) {
      this.scrollWrapper.nativeElement.scrollTop = 0;
    }

    /** set excludes & includes in targets */
    if (this.type === 'dict') {
      this.dicts.available.dict = { data: [] };
      this.dicts.selected.dict = { data: [] };
    }

    this.loadDicts();
  }

  private processResult(result: LazyLoadDict, dict: ExchangeFilterItemDict, isNewSearch: boolean): void {
    dict.isLoading = false;
    dict.dict.data = isNewSearch || !dict.isLazy ? result.data : dict.dict.data.concat(result.data);

    if (result.meta) {
      dict.dict.meta = result.meta;
      dict.isLazy = result.meta.isLazy !== undefined ? result.meta.isLazy : result.meta.total_pages > result.meta.current_page;
    } else {
      dict.isLazy = false;
    }
  }

  private loadAvailableData(): void {
    const availableData = {
      value: this.availableSearchControl.value,
      isNewSearch: true,
      type: 'available'
    } as LoadExchangeDictConfig;

    this.loadDictsData(availableData);
  }

  private loadSelectedData(): void {
    const selectedData = {
      value: this.selectedSearchControl.value,
      isNewSearch: true,
      type: 'selected'
    } as LoadExchangeDictConfig;

    this.loadDictsData(selectedData);
  }

  private clearSelected(): void {
    this.selected.forEach(item => this.remove(item));
    this.clearFilterValues();

    if (this.dicts.selected.isLazy) {
      const data = {
        value: this.selectedSearchControl.value,
        isNewSearch: true,
        type: 'selected'
      } as LoadSspDictConfig;

      this.loadDictsData(data);
    }
  }

  private clearFilterValues(): void {
    this.filterValues.setValue([]);
    this.group.updateValueAndValidity();
  }
}
