import { NgModule } from '@angular/core';
import { ExchangeLayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { GuiModule } from '../../gui/gui.module';
import { SspGridComponent } from './components/ssp-grid/ssp-grid.component';
import { DspGridComponent } from './components/dsp-grid/dsp-grid.component';
import { DynamicFilterModule } from '../../dynamic-modules/dynamic-filter/dynamic-filter.module';
import { DynamicGridModule } from '../../dynamic-modules/dynamic-grid/dynamic-grid.module';
import { ExchangeSspViewComponent } from './components/ssp-view/ssp-view.component';
import { SspFormComponent } from './components/ssp-form/ssp-form.component';
import { DspFormComponent } from './components/dsp-form/dsp-form.component';
import { ExchangeDspViewComponent } from './components/dsp-view/dsp-view.component';
import { ExchangeFilterComponent } from './components/exchange-filter/exchange-filter.component';
import { CampaignFormModule } from '../trade-desk/modules/campaign-form/campaign-form.module';
import { GeoRevshareComponent } from './components/geo-revshare/geo-revshare.component';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { QualityReportGridComponent } from './components/quality-report-grid/quality-report-grid.component';
import { ReportsModule } from '../reports/reports.module';
import { PublicApiKeyComponent } from './components/public-api-key/public-api-key.component';
import { routes } from './exchange.routes';

const ENTRY_COMPONENTS = [
  ExchangeLayoutComponent,
  SspGridComponent,
  DspGridComponent,
  ExchangeDspViewComponent,
  ExchangeSspViewComponent,
  SspFormComponent,
  DspFormComponent,
  ExchangeFilterComponent,
  GeoRevshareComponent,
  ReportGridComponent,
  QualityReportGridComponent,
  PublicApiKeyComponent
];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [
    GuiModule,
    RouterModule,
    DynamicFilterModule,
    DynamicGridModule,
    CampaignFormModule,
    ReportsModule,
    RouterModule.forChild(routes)
  ],
  exports: [PublicApiKeyComponent]
})
export class ExchangeModule {}
