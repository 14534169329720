<ng-container *ngIf="revShares.controls.length">
  <div class="revshare__header">
    <div class="revshare__header-column">Geo</div>
    <div class="revshare__header-column">Revenue</div>
  </div>

  <div class="revshare__list">
    <div
      *ngFor="let revShare of revShares.controls; let i = index;"
      [formGroup]="$any(revShare)"
      class="revshare__list-row"
    >
      <div class="revshare__list-column">
        <ng-container *ngTemplateOutlet="revShare.get('geo').value ? geo : newGeo"></ng-container>

        <ng-template #geo>
          <div class="geo">{{ getGeoValue(revShare.get('geo').value) }}</div>
        </ng-template>

        <ng-template #newGeo>
          <adxad-form-field class="field-group w100">
            <adxad-select
              (filterValue)="filterValue[i] = $event"
              (selectOption)="revShare.get('geo').setValue($event)"
              [filterable]="true"
              [options]="filteredGeos(i)"
              formControlName="geo"
              placeholder="Select geo"
            ></adxad-select>
            <app-show-errors [control]="revShare.get('geo')" />
          </adxad-form-field>
        </ng-template>
      </div>

      <div class="revshare__list-column --actions">
        <adxad-form-field class="field-group w100">
          <adxad-number-input
            [digits]="0"
            [step]="1"
            formControlName="revenue"
          />
          <app-show-errors [control]="revShare.get('revenue')" />
        </adxad-form-field>

        <button
          (click)="removeGeo(i)"
          adxadStrokedButton
          icon="delete_outline"
          iconHoverColor="error"
        >
          {{ 'action_delete' | transloco }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

<button
  (click)="addGeo()"
  adxadButton
  class="w100"
>
  {{ 'Add new geo' | uppercase }}
</button>
