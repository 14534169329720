import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';

/**
 * @return observable form control changes by id
 * @param {FormGroup} form
 * @param {string} controlName
 */
export function getControlChanges(form: FormGroup, controlName: string): Observable<any> {
  return form.get(controlName).valueChanges;
}
