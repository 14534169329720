import { Injectable } from '@angular/core';
import {
  ApiKeyResponse,
  Config,
  EntityResponse,
  GroupsConfig,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  ReportsData,
  ValidateBulkSelect
} from '../../core/interface';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DspService, Request } from '../../core/services/dsp.service';
import { Observable } from 'rxjs';
import {
  ExchangeDSPForm,
  ExchangeDSPResponse,
  ExchangeDSPsData,
  ExchangeSSPForm,
  ExchangeSSPResponse,
  ExchangeSSPsData
} from '../../core/interface/exchange';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private dspService: DspService
  ) {}

  /**
   * Retrieves the Exchange SSPs data based on the provided request.
   *
   * @param {Request} request - The request object containing the necessary parameters.
   * @return {Observable<ExchangeSSPsData>} - The observable that emits the Exchange SSPs data.
   */
  getSSPs(request: Request): Observable<ExchangeSSPsData> {
    return this.http.get<ExchangeSSPsData>(`${this.config.dsp_endpoint}/exchange/ssp/grid`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Adds a new SSP (Supply-Side Platform) entity to the exchange.
   *
   * @param {ExchangeSSPForm} data - The data of the SSP to be added.
   * @return {Observable<EntityResponse>} - An observable that represents the HTTP response containing the entity response.
   */
  addSsp(data: ExchangeSSPForm): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/exchange/ssp/create`, data, { headers });
  }

  /**
   * Edits a SSP (Supply Side Platform) exchange record in the server.
   *
   * @param {ExchangeSSPForm} data - The data for the SSP exchange record to be edited.
   * @return {Observable<EntityResponse>} - An Observable that emits an EntityResponse object.
   */
  editSsp(data: ExchangeSSPForm): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.dsp_endpoint}/exchange/ssp/update`, data, { headers });
  }

  /**
   * Retrieves the Exchange SSP response based on the provided request.
   * @param {Request} request - The request object containing the necessary parameters.
   * @return {Observable<ExchangeSSPResponse>} - An observable that emits the Exchange SSP response.
   */
  getSsp(request: Request): Observable<ExchangeSSPResponse> {
    return this.http.get<ExchangeSSPResponse>(`${this.config.dsp_endpoint}/exchange/ssp/get`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Retrieves the SSP form data from the specified endpoint.
   *
   * @param {Request} request - The request object containing the necessary parameters.
   * @return {Observable<ExchangeSSPResponse>} - An Observable that emits the SSP form data.
   */
  getSspForm(request: Request): Observable<ExchangeSSPResponse> {
    return this.http.get<ExchangeSSPResponse>(`${this.config.dsp_endpoint}/exchange/ssp/getForm`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Retrieves ExchangeDSPsData from the server.
   *
   * @param {Request} request - The request object used to fetch the DSP data.
   * @returns {Observable<ExchangeDSPsData>} - An observable that emits the retrieved ExchangeDSPsData.
   */
  getDSPs(request: Request): Observable<ExchangeDSPsData> {
    return this.http.get<ExchangeDSPsData>(`${this.config.dsp_endpoint}/exchange/dsp/grid`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Adds a DSP (Demand-Side Platform) using the specified data.
   *
   * @param {ExchangeDSPForm} data - The data for creating the DSP.
   * @return {Observable<EntityResponse>} - The observable that will emit the response from the server.
   */
  addDsp(data: ExchangeDSPForm): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.dsp_endpoint}/exchange/dsp/create`, data, { headers });
  }

  /**
   * Edits a DSP (Demand Side Platform) using the provided data.
   *
   * @param {ExchangeDSPForm} data - The data containing the DSP information to be updated.
   * @return {Observable<EntityResponse>} - An observable that emits the response containing the updated DSP.
   */
  editDsp(data: ExchangeDSPForm): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.dsp_endpoint}/exchange/dsp/update`, data, { headers });
  }

  /**
   * Retrieves exchange DSP response.
   *
   * @param {Request} request - The request object.
   * @return {Observable<ExchangeDSPResponse>} - The observable that emits the exchange DSP response.
   */
  getDsp(request: Request): Observable<ExchangeDSPResponse> {
    return this.http.get<ExchangeDSPResponse>(`${this.config.dsp_endpoint}/exchange/dsp/get`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Retrieves the DSP form for the given request.
   *
   * @param {Request} request - The request object.
   * @return {Observable<ExchangeDSPResponse>} - An observable that emits the response object.
   */
  getDspForm(request: Request): Observable<ExchangeDSPResponse> {
    return this.http.get<ExchangeDSPResponse>(`${this.config.dsp_endpoint}/exchange/dsp/getForm`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Changes the SSP status by sending a PUT request to the server.
   *
   * @param {MultiChangeStatus} data - The data containing the updated status.
   * @returns {Observable<MultiChangeStatusResponse>} - An observable that emits the response from the server.
   */
  changeSspStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.dsp_endpoint}/exchange_ssp/status`, data, { headers });
  }

  /**
   * Change the DSP status.
   *
   * @param {MultiChangeStatus} data - The data containing the new status information.
   * @return {Observable<MultiChangeStatusResponse>} - The observable that emits the response of the status change request.
   */
  changeDspStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.dsp_endpoint}/exchange_dsp/status`, data, { headers });
  }

  /**
   * Validate bulk selected values
   * @param {string} url
   * @param data
   */
  validateBulkSelect(url: string, data): Observable<ValidateBulkSelect> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<ValidateBulkSelect>(url, data, { headers });
  }

  /**
   * Get main report grid
   * @param request
   */
  getReport(request: Request): Observable<ReportsData> {
    return this.http.get<ReportsData>(`${this.config.dsp_endpoint}/exchange/report/main`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Get main report groups config
   */
  getReportGroups(): Observable<GroupsConfig> {
    return this.http.get<GroupsConfig>(`${this.config.dsp_endpoint}/exchange/report/main/groups`);
  }

  /**
   * Get quality report grid
   * @param request
   */
  getQualityReport(request: Request): Observable<ReportsData> {
    return this.http.get<ReportsData>(`${this.config.dsp_endpoint}/exchange/report/quality`, {
      params: this.dspService.prepareRequest(request)
    });
  }

  /**
   * Get quality report groups config
   */
  getQualityReportGroups(): Observable<GroupsConfig> {
    return this.http.get<GroupsConfig>(`${this.config.dsp_endpoint}/exchange/report/quality/groups`);
  }

  getSspPublicApiKey(id: string): Observable<ApiKeyResponse> {
    return this.http.get<ApiKeyResponse>(`${this.config.dsp_endpoint}/exchange/ssp/apiKey/generate?id=${id}`);
  }

  getDspPublicApiKey(id: string): Observable<ApiKeyResponse> {
    return this.http.get<ApiKeyResponse>(`${this.config.dsp_endpoint}/exchange/dsp/apiKey/generate?id=${id}`);
  }
}
