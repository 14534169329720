<adxad-loader
  *ngIf="isLoading"
  [diameter]="40"
  class="loader"
></adxad-loader>
<ng-container *ngIf="!isLoading">
  <ul class="filter-list">
    <li
      (click)="selectFilter(filter.id)"
      *ngFor="let filter of filters; let i = index;"
      [ngClass]="{ active: filter.id === selectedFilter.id }"
      class="filter-list__item"
    >
      {{ filter.label | transloco }}

      <div
        *ngIf="selectedItemCount(filter.id) > 0"
        class="filter-list__item-count"
      >
        {{ selectedItemCount(filter.id) }}
      </div>
    </li>
  </ul>

  <div class="filter-value">
    <div class="filter-value__mode">
      <adxad-radio-button
        (changeValue)="group.get(selectedFilter.id).get('mode').setValue($event)"
        [options]="modeType"
        [value]="group.get(selectedFilter.id).get('mode').value"
      />

      <button
        (click)="openBulkSelectedDialog()"
        adxadStrokedButton
        class="filter-value__btn-bulk"
        *ngIf="selectedFilter.id !== 'dspFilter' && selectedFilter.id !== 'sspFilter'"
      >
        Bulk selected
      </button>
    </div>

    <div
      [class.is-dict]="type === 'dict'"
      class="filter-value__actions"
    >
      <ng-container *ngIf="type === 'dict'">
        <div class="filter-value__actions-available">
          <div class="header">
            Available
            <div
              (click)="selectAll()"
              [class.disabled]="isDisabledSelectBtn"
              class="selected-all"
            >
              Selected all
            </div>
          </div>

          <div class="filter-value__search">
            <span class="material-icons filter-value__search-icon"> manage_search </span>
            <input
              [formControl]="availableSearchControl"
              class="filter-value__search-field"
              placeholder="Search"
            />
          </div>

          <div
            #scrollWrapper
            (scroll)="scrollTargets($event, 'available')"
            class="filter-value__values"
          >
            <div
              (click)="add(available)"
              *ngFor="let available of available"
              class="item"
            >
              {{ available.value }}
              <span class="material-icons">check</span>
            </div>

            <adxad-loader
              *ngIf="isAvailableLoader"
              [diameter]="24"
              class="loader"
            ></adxad-loader>
          </div>
        </div>
        <div class="filter-value__actions-selected">
          <div class="header">
            Selected
            <div
              (click)="clearAll()"
              [class.disabled]="isDisabledClearBtn"
              class="clear-all"
            >
              Clear all
            </div>
          </div>

          <div class="filter-value__search">
            <span class="material-icons filter-value__search-icon"> manage_search </span>
            <input
              [formControl]="selectedSearchControl"
              class="filter-value__search-field"
              placeholder="Search"
            />
          </div>

          <div class="filter-value__values">
            <div
              (click)="remove(selected)"
              *ngFor="let selected of selected"
              class="item"
            >
              {{ selected.value }}
              <span class="material-icons">close</span>
            </div>

            <adxad-loader
              *ngIf="isSelectedLoader"
              [diameter]="24"
              class="loader"
            />
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="type === 'subId'">
        <div class="filter-value__actions-selected">
          <div class="row">
            <adxad-form-field class="col">
              <adxad-input
                (keydown)="keyboardListener($event)"
                [(ngModel)]="inputValue"
                placeholder="Add ID"
              ></adxad-input>
            </adxad-form-field>

            <div class="col-auto">
              <button
                (click)="add()"
                [disabled]="disabledAddBtn"
                adxadButton
              >
                Add
              </button>
            </div>
          </div>

          <ng-container *ngIf="filterValues.value?.length">
            <div class="header">
              Selected
              <div
                (click)="clearAll()"
                [class.disabled]="isDisabledClearBtn"
                class="clear-all"
              >
                Clear all
              </div>
            </div>

            <div class="filter-value__search">
              <span class="material-icons filter-value__search-icon"> search </span>
              <input
                [(ngModel)]="filterValue"
                class="filter-value__search-field"
                placeholder="Search"
              />
            </div>
          </ng-container>

          <div class="filter-value__values">
            <div
              *ngFor="let selected of group.get(selectedFilter.id).get('value').value | searchContext: filterValue"
              class="item"
            >
              {{ selected }}
              <span
                (click)="remove(selected)"
                class="material-icons"
              >
                close
              </span>
            </div>

            <adxad-loader
              *ngIf="isSelectedLoader"
              [diameter]="24"
              class="loader"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
