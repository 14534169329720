import { Routes } from '@angular/router';
import { ExchangeLayoutComponent } from './layout/layout.component';
import { MainGuard } from '../../core/guard/main.guard';
import { SspGridComponent } from './components/ssp-grid/ssp-grid.component';
import { DspGridComponent } from './components/dsp-grid/dsp-grid.component';
import { ReportGridComponent } from './components/report-grid/report-grid.component';
import { QualityReportGridComponent } from './components/quality-report-grid/quality-report-grid.component';
import { EXCHANGE_ROUTE } from '../../core/routes';

export const routes: Routes = [
  {
    path: '',
    component: ExchangeLayoutComponent,
    canActivateChild: [MainGuard],
    children: [
      {
        path: '',
        redirectTo: EXCHANGE_ROUTE.ssps,
        pathMatch: 'full'
      },
      {
        path: EXCHANGE_ROUTE.ssps,
        component: SspGridComponent,
        title: "Exchange SSP's"
      },
      {
        path: EXCHANGE_ROUTE.dsps,
        component: DspGridComponent,
        title: "Exchange DSP's"
      },
      {
        path: EXCHANGE_ROUTE.report,
        component: ReportGridComponent,
        title: 'Exchange report'
      },
      {
        path: EXCHANGE_ROUTE.qualityReport,
        component: QualityReportGridComponent,
        title: 'Exchange quality report'
      }
    ]
  }
];
