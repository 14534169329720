import { Component, DestroyRef, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DynamicGridComponent } from '../../../../dynamic-modules/dynamic-grid/components/dynamic-grid/dynamic-grid.component';
import { PaginatorComponent } from '../../../../ui/modules/paginator/paginator.component';
import { DynamicGridAction, DynamicGridColumn, GridRequest } from '../../../../core/interface';
import { Observable, Subject } from 'rxjs';
import { ExchangeDSP, ExchangeDSPsData } from '../../../../core/interface/exchange';
import { switchMap, tap } from 'rxjs/operators';
import { ExchangeService } from '../../exchange.service';
import { MessageService } from '../../../../core/services/message.service';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { GlobicaService } from '../../../../core/services/globica.service';
import { TranslocoService } from '@jsverse/transloco';
import { Status } from '../../../../core/configs';
import { ExchangeDspViewComponent } from '../dsp-view/dsp-view.component';
import { DspFormComponent } from '../dsp-form/dsp-form.component';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-dsp-grid',
  templateUrl: './dsp-grid.component.html',
  styleUrls: ['./dsp-grid.component.scss']
})
export class DspGridComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicGridComponent, { static: true })
  dynamicGrid: DynamicGridComponent;

  @ViewChild(PaginatorComponent, { static: true })
  paginator: PaginatorComponent;

  public isLoading = false;
  public isNotFound = false;
  public isNotCreated = false;
  public readonly Status = Status;
  public columns: DynamicGridColumn[] = [];
  public isLoadingChangeStatus = false;

  private destroyRef = inject(DestroyRef);

  private requestGrid$ = new Subject<GridRequest>();
  private loadGrid$: Observable<ExchangeDSPsData> = this.requestGrid$.pipe(
    tap(() => {
      this.isLoading = true;
      this.resetGrid();
    }),
    switchMap(data => this.exchangeService.getDSPs(data))
  );

  constructor(
    private exchangeService: ExchangeService,
    private messageService: MessageService,
    private alerts: AdxadAlerts,
    private sidebarModal: AdxadSidebarModal,
    public globica: GlobicaService,
    private translate: TranslocoService,
    private modal: AdxadModal
  ) {}

  ngOnInit(): void {
    this.paginator.init();

    this.loadGrid$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (result: ExchangeDSPsData) => {
        this.isLoading = false;
        this.columns = result.meta?.columns;
        this.setGrid(result);
      },
      error: () => (this.isLoading = false)
    });

    this.messageService
      .get('reload-exchange-dsps-grid')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        if (data.submit) {
          this.loadGrid();
        }
      });

    this.loadGrid();
  }

  /**
   * Set requestGrid$ to load grid
   */
  loadGrid(): void {
    const request = {
      sort: this.dynamicGrid?.sort?.active,
      direction: this.dynamicGrid?.sort?.direction.toString(),
      limit: this.paginator.limit,
      page: this.paginator.page
    } as GridRequest;

    this.requestGrid$.next(request);
  }

  /**
   * Clear dynamic grid,
   * Clear paginator,
   * not created & not found flags set false
   */
  resetGrid(): void {
    this.dynamicGrid.clearGrid();
    this.paginator.clearList();
    this.isNotCreated = false;
    this.isNotFound = false;
  }

  /**
   * openView - load & open dsp detail in sidebar modal
   *
   * @param {DynamicGridAction} action contains type and value
   */
  gridAction(action: DynamicGridAction): void {
    if (!action || !action.value || !action.action) {
      return;
    }

    const dsp = action.value as ExchangeDSP;

    if (action.action === 'toggleStatus') {
      const status = dsp.status === this.Status.active ? this.Status.stopped : this.Status.active;
      this.changeStatus([dsp], status);
    }

    if (action.action === 'openView') {
      if (!action.value.id) {
        return;
      }
      this.openSidebarModal(action.value.id);
    }
  }

  /**
   * Set new status to ssp
   *
   * @param dsp
   * @param status
   */
  changeStatus(dsp: ExchangeDSP[], status: number): void {
    if (this.isLoadingChangeStatus) {
      return;
    }
    this.isLoadingChangeStatus = true;

    const data = {
      ids: dsp.map(x => x.id),
      status: status
    };

    dsp.forEach(x => (x.isLoading = true));
    this.exchangeService
      .changeDspStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingChangeStatus = false;

          if (result.status === 'OK') {
            if (!result.data || !result.data.length) {
              this.alerts.error('Oops, something wrong', 3000);
              return;
            }

            dsp.forEach(x => {
              x.isLoading = false;

              const obj = result.data.find(y => x.id === y.id);
              if (!obj) {
                this.alerts.error('DSP ' + x.name + ' has not changed status', 3000);
                return true;
              }

              x.status = obj.status;
              const message = 'The DSP ' + x.name + (x.status === this.Status.stopped ? ' stopped' : ' activated');
              this.alerts.success(message, 3000);
            });
          }
        },
        error: () => {
          this.isLoadingChangeStatus = false;
          dsp.forEach(x => (x.isLoading = false));
        }
      });
  }

  /**
   * Open sidebar modal for view payment details
   *
   * @param id
   */
  openSidebarModal(id: string): void {
    if (!id) {
      this.alerts.error('Oops something went wrong', 3000);
      return;
    }

    this.sidebarModal.open(ExchangeDspViewComponent, {
      data: {
        id: id
      }
    });
  }

  /**
   * Open create dsp form
   */
  openForm(): void {
    this.modal
      .open(DspFormComponent, {
        width: '1120px'
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        if (value && value.submit) {
          this.loadGrid();
        }
      });
  }

  ngOnDestroy() {
    this.messageService.remove('reload-exchange-dsps-grid');
  }

  /**
   * Set DSPs grid
   * @param {CampaignData} result
   */
  private setGrid(result: ExchangeDSPsData): void {
    if (!result || !result.meta || !result.meta.columns || result.status !== 'OK') {
      this.alerts.error(this.translate.translate('alert_somethingWentWrong'), 3000);
      return;
    }

    if (result.data && result.data.length) {
      this.dynamicGrid.setGrid(result);
      this.paginator.createList(result.meta.total);
    } else {
      result.meta.isFilterable ? (this.isNotFound = true) : (this.isNotCreated = true);
    }
  }
}
