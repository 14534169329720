import { Component, DestroyRef, HostBinding, inject, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { AdxadAlerts } from '../../../../ui/modules/alerts/components/alerts/alerts.component';
import { ExchangeService } from '../../exchange.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-public-api-key',
  templateUrl: './public-api-key.component.html',
  styleUrls: ['./public-api-key.component.scss']
})
export class PublicApiKeyComponent implements OnInit {
  @Input({ required: true })
  apiKey = '';

  @Input({ required: true })
  id: string = null;

  @Input({ required: true })
  type: 'ssp' | 'dsp' = null;

  key$ = new BehaviorSubject<string>('');
  private destroyRef = inject(DestroyRef);

  constructor(
    private clipboard: Clipboard,
    private exchangeService: ExchangeService,
    private alerts: AdxadAlerts
  ) {}

  @HostBinding('class.is-empty')
  get currentApiKey() {
    return this.apiKey === null;
  }

  ngOnInit(): void {
    if (this.apiKey) {
      this.key$.next(this.apiKey);
    }
  }

  /**
   * Generate a new API key
   */
  generateKey(): void {
    const getApiKeyObservable =
      this.type === 'ssp' ? () => this.exchangeService.getSspPublicApiKey(this.id) : () => this.exchangeService.getDspPublicApiKey(this.id);

    getApiKeyObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          const apiKey = result.data.apiKey;
          this.key$.next(apiKey);
          this.apiKey = apiKey;
        },
        error: () => this.key$.next(null)
      });
  }

  /**
   * Copy the value to the clipboard
   */
  copyValue(): void {
    this.clipboard.copy(this.key$.value);
    this.alerts.success('Copied successfully', 1500);
  }
}
