<div class="header">
  <section class="filter-toolbar">
    <button
      (click)="openForm()"
      adxadButton
      class="red"
      icon="add"
    >
      {{ 'Create ssp' | uppercase }}
    </button>
  </section>
</div>

<adxad-dynamic-grid
  (action)="gridAction($event)"
  (reload)="loadGrid()"
  [loading]="isLoading"
  [ngClass]="{ 'hide': isNotFound || isNotCreated }"
/>

<adxad-grid-not-found
  *ngIf="isNotFound"
  [showClearFilters]="false"
/>

<section
  *ngIf="isNotCreated"
  class="not-created"
>
  <img
    alt=""
    class="not-creates__icon"
    src="/assets/images/icons/not-created-icon.svg"
  />
  <h2 class="not-created__title">No SSP's</h2>
  <p class="not-created__text">There`s no ssp yet.</p>
  <button
    (click)="openForm()"
    adxadButton
    color="red"
  >
    {{ 'Add ssp' | uppercase }}
  </button>
</section>

<adxad-paginator
  (changePage)="loadGrid()"
  [disabled]="isLoading"
/>
